<template>
  <div>
    <div style="background:#010b30">
      <el-image width="1920" height="568" :src="bannerUrl"></el-image>
    </div>

    <div style="width:100%; height:68px; background:#475AFF">
      <div style="width:1378px; margin:0 auto; text-align:left">
        <ul>
          <router-link
            :to="{ path: '/components/web/aboutus/corporateVision' }"
          >
            <li class="tabStyleLi">
              <div class="tabStyle"></div>
              <div
                class="tabStyle2"
                style="cursor: pointer;position: absolute; top:0; left:50px; padding-top:12px;  color: #ffffff;"
              >
                <div>01</div>
                <div>{{ $t('Nav.qiyegaishu') }}</div>
              </div>
            </li>
          </router-link>

          <li class="tabStyleLi">
            <div class="tabStyle" style="background:#3246F4"></div>
            <div
              style="cursor: pointer;position: absolute; top:0; left:50px; padding-top:12px;  color: #ffffff;"
            >
              <div>02</div>
              <div>{{ $t('Nav.qiyeyuanjing') }}</div>
            </div>
          </li>
          <router-link :to="{ path: '/components/web/aboutus/zyzz' }">
            <li class="tabStyleLi">
              <div class="tabStyle"></div>
              <div
                style="cursor: pointer;position: absolute; top:0; left:50px; padding-top:12px;  color: #ffffff;"
              >
                <div>03</div>
                <div>{{ $t('Nav.zhuanyezizhi') }}</div>
              </div>
            </li>
          </router-link>
          <router-link :to="{ path: '/components/web/joinus/case04' }">
            <li class="tabStyleLi">
              <div class="tabStyle"></div>
              <div
                style="cursor: pointer;position: absolute; top:0; left:50px; padding-top:12px;  color: #ffffff;"
              >
                <div>04</div>
                <div>{{ $t('Nav.qiyewenhua') }}</div>
              </div>
            </li>
          </router-link>
        </ul>
      </div>
    </div>
    <div
      style="width:1200px; margin:0 auto; height: 44px;box-shadow: 0px 1px 0px 0px rgba(71, 90, 255, 0.1);"
    >
      <el-breadcrumb separator="/">
        <el-breadcrumb-item :to="{ path: '/' }"
          ><span style="color:#303133">{{
            $t('Nav.home')
          }}</span></el-breadcrumb-item
        >
        <el-breadcrumb-item :to="{ path: '/components/web/aboutus/index' }"
          ><span style="color:#303133">{{
            $t('Nav.abus')
          }}</span></el-breadcrumb-item
        >
        <el-breadcrumb-item
          ><span style="color:#303133">{{
            $t('Nav.qiyeyuanjing')
          }}</span></el-breadcrumb-item
        >
      </el-breadcrumb>
    </div>

    <div style="width:1200px; margin:0 auto;">
      <div style="width:1200px; margin:0 auto; height:500px">
        <div style="float:left;width:500px; margin-top:150px;">
          <div
            style="margin-left:29px; width: 500px;text-align:left; height: 24px;font-size: 24px;font-family: Noto Sans S Chinese;font-weight: bold;color: #475AFF;line-height: 37px;"
          >
            {{ $t('Nav.qiyeyuanjingText01') }}
          </div>
          <div
            style="text-indent:30px; margin-top:30px;text-align:left; line-height:28px; "
          >
            {{ $t('Nav.qiyeyuanjingText02') }}
          </div>
        </div>
        <div style="float:right;margin-top:50px;">
          <el-image
            :src="qiyeyuanj"
            style="width:640px; height:320px;"
          ></el-image>
        </div>
      </div>
      <div
        style="width:1200px; margin:0 auto; height:500px;background:#F6F7F8;"
      >
        <div style="float:left;margin-top:50px;">
          <el-image
            :src="qiyeyuanj2"
            style="width:640px; height:400px;"
          ></el-image>
        </div>
        <div style="float:right;width:500px; margin-top:150px;">
          <div
            style="margin-left:29px;width: 500px;text-align:left; height: 24px;font-size: 24px;font-family: Noto Sans S Chinese;font-weight: bold;color: #475AFF;line-height: 37px;"
          >
            {{ $t('Nav.qiyeyuanjingText03') }}
          </div>
          <div
            style="text-indent:30px; margin-top:30px;text-align:left;line-height:28px; "
          >
            {{ $t('Nav.qiyeyuanjingText04') }}
          </div>
        </div>
      </div>

      <div style="width:1200px; margin:0 auto; height:500px">
        <div style="float:left;width:500px; margin-top:150px;">
          <div
            style="margin-left:29px;width: 500px;text-align:left; height: 24px;font-size: 24px;font-family: Noto Sans S Chinese;font-weight: bold;color: #475AFF;line-height: 37px;"
          >
            {{ $t('Nav.qiyeyuanjingText05') }}
          </div>
          <div
            style="text-indent:30px; margin-top:30px;text-align:left; line-height:28px; "
          >
            {{ $t('Nav.qiyeyuanjingText06') }}
          </div>
        </div>
        <div style="float:right;margin-top:50px;">
          <el-image
            :src="qiyeyuanj3"
            style="width:640px; height:400px;"
          ></el-image>
        </div>
      </div>

      <!-- <el-image :src="qiyeyuanj" fit="" style="margin-bottom:30px;"></el-image>
      <div><i class="el-icon-s-flag"></i>企业使命</div> -->
    </div>
  </div>
</template>

<script>
export default {
  name: 'corporateVision',
  components: {},
  data() {
    return {
      bannerUrl: require('../../../assets/22.jpg'),
      qiyeyuanj: require('../../../assets/joinus/qiyeyuanjing.png'),
      qiyeyuanj2: require('../../../assets/joinus/1.jpeg'),
      qiyeyuanj3: require('../../../assets/joinus/2.jpeg'),
    }
  },
  methods: {},
}
</script>

<style>
ul {
  margin: 0;
}
.tabStyle {
  color: #ffffff;
  display: inline-block;
  height: 68px;
  width: 150px;
}
.tabStyleLi {
  display: inline-block;
  height: 68px;
  position: relative;
}
.tabStyle:hover {
  background: #3246f4 !important;
  opacity: 0.7;
  height: 68px;
  cursor: pointer;
}

.el-image {
  overflow: visible !important;
}
.el-breadcrumb {
  line-height: 44px;
}
</style>
